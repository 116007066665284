import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  constructor() { }
  ngOnInit() {
    $('#mobile-menu').meanmenu({
      meanMenuContainer: '.mobile-menu',
      meanScreenWidth: '767'
    });
    $('.info-bar').on('click', () => {
      $('.extra-info').addClass('info-open');
    });
    $('.close-icon').on('click', () => {
      $('.extra-info').removeClass('info-open');
    });
    const changeClass = (name) => {
      $('.search-icon-area a').removeAttr('class').addClass(name);
    };
    function fixed_top_menu() {
      const windows = $(window);
      windows.on('scroll', () => {
        const headerheight = $('.main-navigation').height();
        const scrollTop = windows.scrollTop();
        if (scrollTop > headerheight) {
          $('.main-navigation').addClass('sticky');
        } else {
          $('.main-navigation').removeClass('sticky');
        }
      });
    }
    fixed_top_menu();
    $.scrollUp({
      scrollText: '<i class="far fa-arrow-alt-circle-up"></i>',
      easingType: 'linear',
      scrollSpeed: 1000,
      animation: 'fade'
    });
  }
}
