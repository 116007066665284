import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';
declare var $: any;

@Component({
  selector: 'app-principal',
  templateUrl: './principal.component.html',
  styleUrls: ['./principal.component.css']
})
export class PrincipalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    AOS.init();
    $('.popup-youtube').magnificPopup({
      type: 'iframe',
      mainClass: 'mfp-fade',
      removalDelay: 160,
      preloader: false,
      fixedContentPos: false
    });
    $('.portfolio-item-slider').slick({
      dots: false,
      arrows: true,
      prevArrow: '<i class="fas fa-chevron-left left-arrow"></i>',
      nextArrow: '<i class="fas fa-chevron-right right-arrow"></i>',
      infinite: true,
      lazyLoad: 'ondemand',
      autoplay: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            dots: false
          }
          },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            dots: false
          }
          },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false
          }
          }
        ]
    });
    $('.big-footer').backgroundMove();
    $.scrollUp({
      scrollText: '<i class="far fa-arrow-alt-circle-up"></i>',
      easingType: 'linear',
      scrollSpeed: 1000,
      animation: 'fade'
    });
  }

}
