import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PrincipalComponent } from './pages/principal/principal.component';
import { ClientesComponent } from './pages/clientes/clientes.component';
import { AboutComponent } from './pages/about/about.component';
import { PlanesComponent } from './pages/planes/planes.component';
import { ContactoComponent } from './pages/contacto/contacto.component';


const routes: Routes = [
  {path: 'inicio', component: PrincipalComponent},
  {path: 'clientes', component: ClientesComponent},
  {path: 'about', component: AboutComponent},
  {path: 'planes', component: PlanesComponent},
  {path: 'contacto', component: ContactoComponent},
  {path: '**', pathMatch: 'full', redirectTo: 'inicio'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
